/* You can add global styles to this file, and also import other style files */
@import '~ngx-toastr/toastr.css';
@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import '~@angular/material/prebuilt-themes/indigo-pink.css'; 
@import '~swiper/swiper-bundle.min.css';
@import "app/theme/styles/base";
@import "app/theme/styles/admin";
//@import "app/theme/styles/theme";
@import "app/theme/styles/gradients";
@import "app/theme/styles/rtl";

.log {
    width: 100px;
    //border-radius: 70%;
   // border-bottom: 10px solid #FFFFFF;
    padding-bottom: -2%;
    padding-left: 10%;
    height: 110px;
    //font-family: 'Open Sans', sans-serif;
  }
  
  .log2 {
    width: 120px;
    /* border-bottom-left-radius: 35% !important;
    border-bottom-right-radius: 38% !important;
    border-top-left-radius: 30% !important;
    border-top-right-radius: 32% !important; */
   // border-bottom: 10px solid #FFFFFF;
   // padding-bottom: -2%;
    padding-right: 10%;
    height: 120px;
    //font-family: 'Open Sans', sans-serif;
  }
  
  .log3 {
    width: 100%;
    height: 100%;
  }

.loading-app {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    -webkit-transition: visibility 0.5s, opacity 0.3s linear;
    -moz-transition: visibility 0.5s, opacity 0.3s linear;
    transition: visibility 0.5s, opacity 0.3s linear;
    h4{
        margin-top: 10px;
        letter-spacing: 0.02em;
        opacity: 0;
        text-transform: uppercase;
        -moz-animation: loading-text-opacity 2s linear 0s infinite normal;
        -o-animation: loading-text-opacity 2s linear 0s infinite normal;
        -webkit-animation: loading-text-opacity 2s linear 0s infinite normal;
        animation: loading-text-opacity 2s linear 0s infinite normal;
    }
  }

html,
body {
  // margin: 10;
  // padding: 10;
  width: 100%;
  height: 100%;
  -webkit-background-size: cover;
  background-size: cover;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

body {
  margin: 0;
  font-family: 'Sailec', Roboto, system-ui, sans-serif;
  font-size: 85.25%;
  font-weight: normal;  
}

.primary-menu mat-icon.mat-icon,
.primary-menu mat-icon.mat-icon + span {
  color: #787878;
}
ngx-wig.heght100 .nw-editor {
  height: 100px !important;
  max-height: 150px !important;
}
.nw-editor {
  font-size: 13px;
  padding-top: 4px;
}
.accord-container {
  margin: 4px;
}
th.fit,
td.fit {
  width: 1%;
  word-wrap: break-word;
  padding-left: 20px !important;
  padding-right: 20px !important;
}
mat-form-field {
  width: 100%;
}
.mat-card-title {
  font-size: 18px;
  font-weight: 500;
}
.mat-button.mat-primary,
.mat-icon-button.mat-primary,
.mat-stroked-button.mat-primary {
  color: #063970;
}
.filter {
  padding-left: 24px;
  .mat-form-field {
    width: 40%;
  }
}
.secondary-menu {
  width: 230px;
  display: flex;
  flex-direction: column;
  // margin-top: 1.25em;
  min-width: fit-content;

  .nav_items__section {
    padding: 0px;
    margin: 0px;
    overflow: auto;

    // box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    //   0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    // max-height: 580px;
  }

  .nav-links__item--active {
    color: #063970;
    border-radius: none !important;
    border-left: 13px solid #ffc107;
    border-right: 13px solid #ffc107;
    background-color: #28512f40;
  }

  .inner_menu__title {
    color: #ffc107;
    font-weight: 900;
    padding: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .mat-list-item {
    font-size: 15px;
    margin-left: 0px;
    border-radius: 0 0px 0px 0;
  }

  .mat-list-base .mat-list-item .mat-list-item-content {
    // padding-left: 48px !important;
    overflow-x: hidden;
  }

  div {
    a {
      align-items: center;
      color: rgb(95, 99, 104);
      display: flex;
      font-weight: 500;
      margin-inline-end: 2px;
      margin-inline-start: 1px;
      min-height: 20px;
      padding-bottom: 10px;
      padding-inline-start: 23px;
      padding-top: 10px;
      text-decoration: none;
      border-left: 5px solid transparent;
    }

    mat-icon {
      --iron-icon-fill-color: var(--settings-nav-icon-color);
      margin-inline-end: 24px;
      pointer-events: none;
      vertical-align: top;
    }
  }
}

.iftmis__title {
  font-family: 'Sailec';
  font-weight: 800 !important;
  span {
    font-weight: 900 !important;
  }
}
span.title {
  color: #5f6368;
  font-size: 14px;
  font-weight: 800;
  margin-right: 4px;
}

div.ou-tree {
  width: 100%;
  margin: 0;
  padding: 16px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 0px;
  background-color: #fff;
  height: 100%;
}

.mat-expansion-panel-header {
  font-size: 13px;
}

.table-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
}

.btn-margin-right {
  margin-right: 14px;
}

table {
  width: 100%;
}

td.mat-column-formActions {
  width: 20px;
  padding-right: 8px !important;
}

tr.data-row:hover {
  background: rgba(0, 0, 0, 0.1);
}

.custom-ou-tree {
  width: 170px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

.mat-tab-body {
  padding: 16px;
}

.mat-expansion-panel-header-title {
  vertical-align: middle;
  line-height: 1.4;
}

button:active,
button:focus,
button:hover,
.button:active,
.button:focus,
.button:hover {
  border: none !important;
  outline: none !important;
}
.badge {
  padding: 0.4rem 1rem 0.4rem !important;
  border-radius: 2rem !important;
}
.xng-breadcrumb-root {
  font-family: 'Sailec';
  font-weight: 500;
  padding: 2px 2px 2px 16px;
  display: inline-block;
  border-radius: 4px;
  background-color: transparent;
  font-size: 15px;
  text-transform: capitalize;
}

.xng-breadcrumb-link {
  margin: 0;
  padding: 0px;
  margin-top: 2px;
  text-transform: capitalize;
}

td.mat-column-formActions {
  width: 20px;
  padding-right: 8px !important;
}

.xng-breadcrumb-separator {
  padding: 0 0px;
}
.xng-breadcrumb-trail {
  margin: 0;
  padding: 0px;
  margin-top: 2px;
  text-transform: capitalize;
}

.mat-dialog-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.7);
}

.mat-dialog-box {
  mat-card {
    box-shadow: none !important;
  }
  .mat-dialog-header {
    background-color: #063970 !important;
    padding: 0.6rem 0.8rem 0.6rem 0.8rem;

    .modal-icon {
      margin-top: 6px;
      color: #fff;
    }

    .header-title {
      h2 {
        display: inline;
        color: #fff;
        text-transform: uppercase;
        font-size: 16px;
        margin-top: -2%;
      }
    }

    .close-button {
      mat-icon {
        color: #fff;
      }

      background-color: rgba(0, 0, 0, 0.013);
      border-radius: 50%;
    }
  }

  mat-dialog-container {
    padding-top: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    overflow-y: hidden !important;
    overflow-x: hidden !important;
    border-radius: 0 !important;
  }

  mat-dialog-content {
    padding: 0rem 3rem !important;
  }

  mat-dialog-title {
    background: #54a0ff !important;
    width: 100% !important;
  }

  mat-dialog-actions {
    padding: 0.7rem 1rem !important;
    background-color: rgba(184, 184, 184, 0.096) !important;
  }
}
// fab styling
.mat-fab {
  top: auto;
  right: 40px;
  bottom: 50px;
  left: auto;
  transform: scale(1);
  position: fixed !important;
  z-index: 20;
  line-height: 56px;
  min-width: 0;
  width: 56px;
  height: 56px;
  vertical-align: middle;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  border-radius: 50%;
  background-clip: padding-box;
  overflow: hidden;
}

.inner_page__heading {
  padding: 0.3rem 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .inner_page__title {
    margin-top: 4px;
    font-size: 1.2rem;
    font-weight: 400;
    color: rgba($color: #323336, $alpha: 1);
  }
}

.inner_page__body {
  padding: 1rem 0px;
}

.loader__container {
  background-color: #f3f3f3;
  position: absolute;
  left: calc(100vw / 2 + 25vw);
  bottom: 20px;
}

.nav-links__item--active {
  color: #063970;
  border-radius: none !important;
  border-right: 10px solid #28a745;
  background-color: #ffc107a6;
}

table.border {
  width: 100%;

  th.mat-header-cell,
  td.mat-cell {
    padding: 0 4px !important;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
  }

  th:first-child,
  td:first-child {
    border-left: 1px solid rgba(0, 0, 0, 0.12);
  }

  th.mat-header-cell {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  }

  tr.mat-header-row {
    height: 32px !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
}

table.border-nlr {
  width: 100%;

  th.mat-header-cell,
  td.mat-cell {
    padding: 0 4px !important;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
  }

  th:first-child,
  td:first-child {
    border-left: 0px solid rgba(0, 0, 0, 0.12);
    padding-left: 12px !important;
  }

  th:last-child,
  td:last-child {
    border-right: 0px solid rgba(0, 0, 0, 0.12);
  }

  th.mat-header-cell {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  }

  tr.mat-header-row {
    height: 32px !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
}
.nopad .mat-expansion-panel-body {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

button.small {
  font-size: 12px;
}
.action-row {
  padding: 0.5rem;
}

select.mult-select option {
  padding: 8px;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.82);
  cursor: pointer;
}

select.mult-select,
mat-selection-list.ift-select {
  border: 1px solid #ced4da;
  min-height: 100px;
  .mat-list-option {
    font-size: 13px !important;
    height: 38px;
    border-bottom: solid 0px #ced4da;
  }

  mat-list-option:last-child {
    border-bottom: none;
  }
}
.ou-tree {
  .mat-checkbox {
    font-size: 13px !important;
  }
  span.area {
    font-size: 14px !important;
  }
}

select.ift-form-control,
input.ift-form-control {
  border: 1px solid #ced4da;
  height: 30px;
  font-size: 13px;
  border-radius: 2px;
  padding: 8px;
}

.ift-header {
  margin-bottom: 12px !important;
  color: rgba(0, 0, 0, 0.87);
  font-family: 'Sailec';
  font-size: 14px;
  font-weight: 500;
}
.mat-toolbar.mat-primary {
  background: #28a745de;
  color: white;
}

.mat-flat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
  background-color: #063970;
}

.mat-flat-button.mat-accent,
.mat-raised-button.mat-accent,
.mat-fab.mat-accent,
.mat-mini-fab.mat-accent {
  background-color: #063970;

  .template {
    margin-left: 0.8%;
    margin-right: 0.8%;
  }
  .font-title {
    height: 2%;
    text-align: center !important;
    line-height: 1.5;
    font-size: 20px;
    padding-top: 1%;
    margin-left: 1%;
    padding-bottom: 1%;
    color: #28a745 !important;
    font-family: "Times New Roman", Times, serif !important;
  }
  
  .font-title2 {
    padding-bottom: 7px;
    font-size: 15px;
    color: #28a745 !important;
    font-family: "Times New Roman", Times, serif !important;
  }
  
  .template-detail {
    margin-left: 3%;
    margin-right: 3%;
    margin-bottom: 3%;
    font-size: 20px;
    font-family: "Times New Roman", Times, serif !important;
    .mat-radio-button ~ .mat-radio-button {
      margin-left: 16px;
    }
  }
}

.example-small-box, .example-large-box {
  display: flex;
  // align-items: center;
  // justify-content: center;
  margin: 10px;
  padding: 10px;
  border-radius: 8px;
}

.example-small-box {
  height: 100px;
  width: 100px;
}

.example-large-box {
  height: auto;
  width: auto;
}

.tab-content {
  border-radius: 8px;
  width: auto;
  padding: 20px; margin:5px; height: auto;
}

.report-tab-content {
  border-radius: 8px;
  width: auto;
  height: auto;
  padding-bottom: 1%;
}

.example-button-toggle-label {
  display: inline-block;
  margin: 16px;
}